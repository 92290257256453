import React, { useEffect, useState } from "react";

import "./Navbar.css";
import { AiOutlineHeart, AiOutlineMenu, AiOutlineSearch } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { HiOutlineShoppingBag } from "react-icons/hi";

import { Link, NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GrClose } from "react-icons/gr";
import { useSelector } from "react-redux";
import { selectBasketItems } from "../../Redux/basketSlice";
import { auth, db } from "../../Firebase/Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  color: white;
  transition: right 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Navbar = () => {
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const items = useSelector(selectBasketItems);

  const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  const [wishlist, setWishlist] = useState(getDatafromLS());

  const [user, setUser] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getDoc(doc(db, "user", user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            setUser(docSnap.data());
          } else {
            console.log("No Document Found");
          }
        });
      } else {
        console.log("no user");

        setUser();
      }
    });
  }, []);

  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
  };

  return (
    <>
      <div className={" fix-nav"}>
        <div>
          <div
            className={`nav-div2 ${
              showButton ? "sticky-nav fadeInDown-nav" : ""
            }`}
          >
            <div
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              className={` ${showButton ? "logo-nav-black" : "logo-nav"}`}
            >
              Bansport
            </div>
            <div className="log-nav-icons">
              <ul className={` ${showButton ? "ul-black-nav" : "ul-nav"}`}>
                <li className={showButton ? "li-black" : "l1"}>
                  <Link to={"/Category"}>Men</Link>
                </li>
                <li className={showButton ? "li-black" : "l1"}>Women</li>
              </ul>
            </div>

            <div className={`${showButton ? "search-nav-gray" : "search-nav"}`}>
              <input
                className={`${
                  showButton ? "search-input-nav1" : "search-input-nav"
                }`}
                placeholder="Search..."
                type="text"
                name=""
                id=""
              />
              <AiOutlineSearch color="#80808063" />
            </div>
            <div
              className={`
            log-nav-icons
              ${
                showButton
                  ? "navbar-right-h-icons-black"
                  : "navbar-right-h-icons"
              }`}
            >
              <Link
                to={"/Wishlist"}
                className={showButton ? "nav-links" : "nav-links-black"}
              >
                <div className="cart-count-nav">
                  <AiOutlineHeart size={22} />
                  <span>({wishlist.length})</span>
                </div>
              </Link>
              <div className="mb-icons-nav">
                <Link
                  to={"/Account"}
                  className={showButton ? "nav-links" : "nav-links-black"}
                >
                  <BiUser size={22} />
                </Link>
              </div>
              <Link
                to={"/Cart"}
                className={showButton ? "nav-links" : "nav-links-black"}
              >
                <div className="cart-count-nav">
                  <HiOutlineShoppingBag size={22} />({items.length})
                </div>
              </Link>
            </div>
            <div className="log-nav-icons">
              {user ? (
                <>
                  <div className="right-h-user">
                    <Link
                      to={"/Account"}
                      className={showButton ? "nav-links" : "nav-links-black"}
                    >
                      <BiUser size={22} />
                    </Link>
                    <Link to={"/Account"}>
                      <h1
                        className={
                          showButton ? "user-name-nav-black" : "user-name-nav"
                        }
                      >
                        {user?.name}
                      </h1>
                    </Link>
                    <button
                      className="btn-log-out-nav"
                      onClick={() => handleSignOut()}
                    >
                      Sign out
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ul className={` ${showButton ? "ul-black-nav" : "ul-nav"}`}>
                    <li className={showButton ? "li-black" : "l1"}>
                      <Link to={"/Signin"}>Sign In</Link>
                    </li>

                    <li className={showButton ? "li-black" : "l1"}>
                      <Link to={"/Signup"}>Sign Up</Link>
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div className="mb-icons-nav">
              <div className="mb-icons-nav1">
                <Link
                  to={"/Wishlist"}
                  className={showButton ? "nav-links" : "nav-links-black"}
                >
                  <AiOutlineHeart size={22} />
                </Link>
                <Link
                  to={"/Cart"}
                  className={showButton ? "nav-links" : "nav-links-black"}
                >
                  <HiOutlineShoppingBag size={22} />
                </Link>
                <span
                  onClick={() => setIsOpen(true)}
                  className={showButton ? "nav-links" : "nav-links-black"}
                >
                  <AiOutlineMenu size={22} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v className={isOpen ? "none-f-sidenav" : "none-d-sidenav"}>
        <StyledOffCanvas isOpen={isOpen}>
          <div className={isOpen ? "head-mobile-menu" : "none-d-sidenav"}>
            <div className="close-icon-nav">
              <GrClose
                color="black"
                size={20}
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className="">
              <ul className={"side-bar-menu-ul"}>
                {user ? (
                  <>
                    {" "}
                    <div className="right-h-user1">
                      <Link to={"/Account"} className="right-h-user">
                        <BiUser color="black" size={22} />
                        <h1 className={"user-name-nav-black"}>{user?.name}</h1>
                      </Link>
                      <Link
                        to={"/Cart"}
                        className={showButton ? "nav-links" : "nav-links-black"}
                      >
                        <AiOutlineSearch color="black" size={22} />
                      </Link>
                    </div>{" "}
                  </>
                ) : (
                  <></>
                )}

                <li className={""}>
                  <Link to={"/Category"}>Men</Link>
                </li>

                <li className={""}>
                  <Link to={"/Category"}>Men</Link>
                </li>
              </ul>
              <div className="side-nav-btn-div">
                {user ? (
                  <>
                    <button
                      className="btn-side-nav1"
                      onClick={() => handleSignOut()}
                    >
                      Sign out
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn-side-nav1"
                      onClick={() => navigate("/Signin")}
                    >
                      Sign in
                    </button>
                    <button
                      onClick={() => navigate("/Signup")}
                      className="btn-side-nav2"
                    >
                      Sign up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </StyledOffCanvas>
        <StyledOffCanvasOverlay
          isOpen={isOpen}
          onClick={() => setIsOpen(false)}
        />
      </div>
    </>
  );
};

export default Navbar;
