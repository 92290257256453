import React, { useMemo, useState } from "react";
import "./Cart.css";
import Navbar2 from "../../components/Layout/Navbar/Navbar2";
import { AiOutlineHeart } from "react-icons/ai";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromBasket,
  selectBasketItems,
  selectBasketTotal,
} from "../../components/Redux/basketSlice";
import Currency from "react-currency-formatter";

const Cart = () => {
  const navigate = useNavigate();

  const items = useSelector(selectBasketItems);
  const [groupedItemsInBasket, setGroupedItemsInBasket] = useState([]);
  const dispatch = useDispatch();
  useMemo(() => {
    const groupedItems = items.reduce((results, item) => {
      (results[item.id] = results[item.id] || []).push(item);
      return results;
    }, {});

    setGroupedItemsInBasket(groupedItems);
  }, [items]);
  const basketTotal = useSelector(selectBasketTotal);

  return (
    <>
      <Navbar2 />
      <div className="cart-m-1">
        <div className="cart-m-2">
          {items.length === 0 ? (
        <>
        
        <div className="empty-cart-div">
          <h1 className="cart-head-head">Your Bag is Empty</h1>
          <button onClick={()=> navigate('/')} className="btn-empty-cart">
            Continue Shoping
          </button>
        </div>
        </>
          ) : (
            <div className="main-div-cart">
              <div className="div-2-cart">
                <div className="left-h-cart">
                  <div className="lh-m1">
                    <div className="heading-bag">
                      <h1>Bag {items.length}</h1>
                    </div>
                    <div className="main-d-p-c">
                      {Object.entries(groupedItemsInBasket).map(
                        ([key, items]) => (
                          <div key={key} className="d-f-cart-f">
                            <div className="div-8-cart">
                              <div className="product-img-cart">
                                <img src={items[0]?.image} alt="" />
                              </div>
                              <div className="cart-gap-1">
                                <div className="d-f-cart2">
                                  <h1 className="product-name-cart">
                                    {" "}
                                    {items[0]?.name}
                                  </h1>
                                </div>
                                {items[0]?.description ? (
                                  <>
                                    <div>
                                      <p className="para-details-cart">
                                        {items[0]?.description}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <p className="para-details-cart"></p>
                                    </div>
                                  </>
                                )}
                                {items[0]?.selectcolor ? (
                                  <>
                                    {" "}
                                    <div>
                                      <h1 className="color-cart-p">
                                        color/{items[0]?.selectcolor}
                                      </h1>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <div className="d-f-cart-3">
                                  <h1 className="color-cart-p">
                                    Size {items[0]?.selectsize}
                                  </h1>
                                  <h1 className="color-cart-p">
                                    Quantity {items.length}
                                  </h1>
                                </div>
                                <div className="d-f-cart-4">
                                  
                                  <MdOutlineDeleteOutline
                                    onClick={() =>
                                      dispatch(removeFromBasket({ id: key }))
                                    }
                                    size={25}
                                    color="gray"
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              <h1 className="p-price-cart">
                                ₹{items[0]?.price}
                              </h1>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="right-h-cart">
                  <div className="heading-pay">
                    <h1>Summary</h1>
                  </div>
                  <div className="head-pay-centent">
                    <div className="d-f-car-5">
                      <h1>Subtotal</h1>
                      <h1>
                        <Currency quantity={basketTotal} currency="INR" />
                      </h1>
                    </div>
                    <div className="d-f-car-5 border-b-c">
                      <h1>Estimated Delivery & Handling </h1>
                      <h1>₹100</h1>
                    </div>
                    <div className="d-f-car-5 border-b-c">
                      <h1>Total</h1>
                      <h1>
                        {" "}
                        <Currency quantity={basketTotal + 100} currency="INR" />
                      </h1>
                    </div>
                    <div className="chec-out-btn-div">
                      <button
                        onClick={() => navigate("/CheckOut")}
                        className="check-out-btnn-cart"
                      >
                   Guest Checkout
                      </button>
                      <button
                        onClick={() => navigate("/Signin")}
                        className="check-out-btnn-cart"
                      >
                 Member Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
