import React, { useMemo, useState } from "react";

import "./CheckOut.css";
import Navbar2 from "../../components/Layout/Navbar/Navbar2";
import { AiOutlineHeart } from "react-icons/ai";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromBasket,
  selectBasketItems,
  selectBasketTotal,
} from "../../components/Redux/basketSlice";
import Currency from "react-currency-formatter";

const CheckOut = () => {
  const navigate = useNavigate();

  const items = useSelector(selectBasketItems);
  const [groupedItemsInBasket, setGroupedItemsInBasket] = useState([]);
  const dispatch = useDispatch();
  useMemo(() => {
    const groupedItems = items.reduce((results, item) => {
      (results[item.id] = results[item.id] || []).push(item);
      return results;
    }, {});

    setGroupedItemsInBasket(groupedItems);
  }, [items]);
  const basketTotal = useSelector(selectBasketTotal);

  return (
    <>
      <Navbar2 />
      <div className="check-d-1">
        <div className="check-d-2">
          <div className="check-content-head-div">
            <div className="left-h-check">
              {/* <div className="check-form-f1">
                <button className="log-btn-check">Login</button>
              </div> */}
              <form
                action="
                    "
              >
                <div className="log-btn-div-check">
                  <h1 className="check-h1">Enter your name and address:</h1>
                  <div className="form-check-address">
                    <div className="form-check-div2">
                      <div>
                        <input
                          className="input-form-check"
                          id="input"
                          type="text"
                          required
                        />
                        <label
                          for="input"
                          alt="Name"
                          placeholder="Name"
                        ></label>
                      </div>
                      <div>
                        <input
                          className="input-form-check"
                          id="input"
                          type="text"
                          required
                        />
                        <label
                          for="input"
                          alt="Address Line 1"
                          placeholder="Address Line 1"
                        ></label>
                      </div>
                      <div>
                        <input
                          className="input-form-check"
                          id="input"
                          type="text"
                          required
                        />
                        <label
                          for="input"
                          alt="Address Line 2"
                          placeholder="Address Line 2"
                        ></label>
                      </div>
                      <div className="d-f-c-8">
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                          />
                          <label
                            for="input"
                            alt="Pin Code"
                            placeholder="Pin Code"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                          />
                          <label
                            for="input"
                            alt="City"
                            placeholder="City"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="check-h1">
                    What's your contact information?:
                  </h1>
                  <div className="form-check-address">
                    <div className="form-check-div2">
                      <div>
                        <input
                          className="input-form-check"
                          id="input"
                          type="text"
                          required
                        />
                        <label
                          for="input"
                          alt="Email "
                          placeholder="Email"
                        ></label>
                      </div>
                      <div>
                        <input
                          className="input-form-check"
                          id="input"
                          type="text"
                          required
                        />
                        <label
                          for="input"
                          alt="Phone Number"
                          placeholder="Phone Number"
                        ></label>
                      </div>
                      <div className="chec-out-btn-div">
                        <button className="check-out-btnn-cart1">
                          Check Out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="right-h-cart">
                  <div className="heading-pay">
                    <h1>Summary</h1>
                  </div>
                  <div className="head-pay-centent">
                    <div className="d-f-car-5">
                      <h1>Subtotal</h1>
                      <h1>

                      <Currency quantity={basketTotal} currency="INR" />
                      </h1>
                    </div>
                    <div className="d-f-car-5 border-b-c">
                      <h1>Estimated Delivery & Handling </h1>
                      <h1>₹100</h1>
                    </div>
                    <div className="d-f-car-5 border-b-c">
                      <h1>Total</h1>
                      <h1>  <Currency quantity={basketTotal + 100} currency="INR" /></h1>
                    </div>
              {Object.entries(groupedItemsInBasket).map(([key, items]) => (
                <div key={key}>
                        <div className="d-f-car-6 ">
                      <div>
                        <img
                          className="check-proimg"
                          src={items[0]?.image}
                          alt=""
                        />
                      </div>
                      <div className="cart-gap-1">
                        <div className="d-f-cart12 ">
                          <h1 className="product-name-cart">
                            {" "}
                            {items[0]?.name}
                          </h1>
                          <h1 className="product-name-cart">
                            ₹{items[0]?.price}
                          </h1>
                        </div>
                        {items[0]?.selectcolor ? (
                                  <>
                                
                                    <div>
                          <h1 className="color-cart-p">color/{items[0]?.selectcolor}</h1>
                        </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                   
                        <div className="d-f-cart-3">
                          <h1 className="color-cart-p">
                            {" "}
                            Size {items[0]?.selectsize}
                          </h1>
                          <h1 className="color-cart-p">Qty {items.length} </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOut;
