import React, { useEffect, useState } from "react";

const Test2 = () => {
  const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  const [wishlist, setWishlist] = useState(getDatafromLS());

  const deleteBook = (isbn) => {
    const filteredBooks = wishlist.filter((element, index) => {
      return element.isbn !== isbn;
    });
    setWishlist(filteredBooks);
  };

  // saving data to local storage
  useEffect(() => {
    localStorage.setItem("Wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  return (
    <div>
      hello
      {wishlist.map((book) => (
        <tr key={book.name}>
          <td>{book.name}</td>
          <td>{book.name}</td>
          <td>{book.name}</td>
          <td className="delete-btn" onClick={() => deleteBook(book.isbn)}>
            delete
          </td>
        </tr>
      ))}
    </div>
  );
};

export default Test2;
