import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, FreeMode } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
const NewCarousel = ({ name }) => {
  const navigate = useNavigate();

  const [swiperRef, setSwiperRef] = useState(null);

  const goPrev = () => {
    if (swiperRef && swiperRef.swiper) {
      swiperRef.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef && swiperRef.swiper) {
      swiperRef.swiper.slideNext();
    }
  };
  const swiperParams = {
    breakpoints: {
      // Breakpoint for screen width >= 320px
      300: {
        slidesPerView: 1,
        // spaceBetween: 10
      },
      300: {
        slidesPerView: 1,
        // spaceBetween: 10
      },
      320: {
        slidesPerView: 1,
        // spaceBetween: 10
      },
      450: {
        slidesPerView: 1,
        // spaceBetween: 10
      },
      // Breakpoint for screen width >= 480px
      480: {
        slidesPerView: 2,
        // spaceBetween: 10
      },
      550: {
        slidesPerView: 1,
        // spaceBetween: 10
      },
      650: {
        slidesPerView: 2,
        // spaceBetween: 10
      },
      // Breakpoint for screen width >= 640px
      700: {
        slidesPerView: 2,
        // spaceBetween: 10
      },
      1000: {
        slidesPerView: 3,
        // spaceBetween: 10
      },
      1400: {
        slidesPerView: 4,
        // spaceBetween: 10
      },
    },
  };

  const [product, setproduct] = useState([]);

  useEffect(() => {
    const todosref = collection(db, "Categories");
    const q = query(todosref, orderBy("num", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setproduct(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="carousel-div1">
        <button className="custom-prev" onClick={goPrev}>
          <BiChevronLeft />
        </button>
        <Swiper
          onSwiper={setSwiperRef}
          slidesPerView={1}
          // spaceBetween={10}
          {...swiperParams}
          navigation={{
            prevEl: ".custom-prev",
            nextEl: ".custom-next",
          }}
          modules={[Pagination, Navigation, FreeMode]}
          className="mySwiper"
        >
          {Array.isArray(product)
            ? product.map((item, key) => {
                return (
                  <>
                    <SwiperSlide
                      onClick={() =>
                        navigate("/Category", { state: { product: item } })
                      }
                    >
                      <div className="new-div5">
                        <div className="new-div6">
                          <img
                            className="slider-img-1"
                            src={item.img2}
                            alt=""
                          />
                          <h1 className="slide-heading">{item.PName}</h1>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })
            : null}
        </Swiper>

        <button className="custom-next" onClick={goNext}>
          <BiChevronRight />
        </button>
      </div>
    </>
  );
};

export default NewCarousel;
