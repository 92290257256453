import React from "react";
import "./Home";
import Navbar from "../../components/Layout/Navbar/Navbar";
import Headerhome from "../../components/HeaderHome/Headerhome";
import Categories from "../../components/Categories/Categories";
import Deals from "../../components/Deals/Deals";
import NewArrivals from "../../components/NewArrivals/NewArrivals";
import Carousel from "../../components/HeaderHome/Carousel";
import Products from "../../components/Products/Products";

import Loader from "../../components/Loader/Loader";

const Home = () => {


  return (
    <>

        <Loader/>
       

        <>
          <Navbar />
          <Carousel />
          <Headerhome />
          <Categories />
          <Deals />
          <NewArrivals />
  
        </>

    </>
  );
};

export default Home;
