import React, { useEffect, useState } from "react";
import "./Category.css";
import Navbar2 from "../../components/Layout/Navbar/Navbar2";
import Categorycard from "./Categorycard";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const Category = () => {
  const location = useLocation();
  const product = location.state.product;

  const [products, setproducts] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products",product.type,product.Name);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setproducts(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  console.log(product);

  return (
    <>
        {/* <Loader/> */}

      <Navbar2 />
      <div className="cate-div1">
        <div className="cate-div2">
          <div className="cate-div">
            <h1 className="cate-heding">{product.PName}</h1>
            <div>

            <div className='cont-cat-card-c'>

            {Array.isArray(products)
              ? products.map((item, key) => {
                  return (
                    <>
                      <Categorycard
                        id={item.id}
                        name={item.Name}
                        image={item.Image}
                        price={item.Price}
                        description={item.Description}
                        descriptionss={item.Descriptionss}
                        available={item.Available}
                        sizeChart={item.sizeChart}
                        category={item.Category}
                        type={item.type}
                      />
                    </>
                  );
                })
              : null}
              
            </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
