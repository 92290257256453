import React from "react";
import "./Category.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Categorycard = ({
  id,
  image,
  name,
  price,
  description,
  available,
  descriptionss,
  sizeChart,

  category,
  type,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const data = {
      id,
      image,
      name,
      price,
      description,
      available,
      descriptionss,
      sizeChart,
      category,
      type,
    };
    navigate("/Product", { state: data });
  };

  return (
    <>
      <div onClick={handleClick} className="card-head-c">
      
          <img className="card-img-c" src={image} alt="" />
      
        <div className="card-con-c">
          <h1 className="pro-name-h4">{name}</h1>
          <p className="pro-dec1">Lorem ipsum dolor sit amet. </p>
          <div className="card-flex-3">
            <h1 className="pro-price-h1">₹{price}</h1>
            <h1 className="pro-offer-c">(30% off)</h1>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

export default Categorycard;
