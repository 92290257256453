import React, { useEffect, useState } from "react";

import "./NewArrivals.css";
import CardNewArrivals from "./CardNewArrivals";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
const NewArrivals = () => {
  const [featured, setfeatured] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "PumpCover");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured2, setfeatured2] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "MuscleTanks");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured2(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured3, setfeatured3] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "HoodiesTracksuit");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured3(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured4, setfeatured4] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Bottom", "Shorts");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured4(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const [featured5, setfeatured5] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Bottom", "QuadShorts");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured5(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const combine = (featured, featured2, featured3, featured4, featured5) => {
    return [
      ...featured,
      ...featured2,
      ...featured3,
      ...featured4,
      ...featured5,
    ];
  };

  return (
    <>
      <div className="new-div1">
        <div className="new-div2">
          <div className="mt-deals">
            <div className="new-flex-1">
              <h1 className="new-h2">Don't Miss</h1>

              {/* <span className="line-texts"></span> */}
              <h1 className="new-h1">New Arrivals</h1>
              {/* <span className="line-texts"></span> */}
            </div>
            <div className="CardNewArrivals-div">
                  
      <div className="cards-div-di">

 
              {Array.isArray(
                combine(featured, featured2, featured3, featured4, featured5)
              )
                ? combine(
                    featured,
                    featured2,
                    featured3,
                    featured4,
                    featured5
                  ).map((item, key) => {
                    return (
                      <>
                        <CardNewArrivals
                          id={item.id}
                          name={item.Name}
                          image={item.Image}
                          price={item.Price}
                          description={item.Description}
                          descriptionss={item.Descriptionss}
                          available={item.Available}
                          sizeChart={item.sizeChart}
                          category={item.Category}
                          type={item.type}
                          newArrivals={item.newArrivals}
                        />
                      </>
                    );
                  })
                : null}
                     </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewArrivals;
