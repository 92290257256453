import React, { useEffect, useState } from "react";

import "./Navbar.css";
import {
  AiOutlineHeart,
  AiOutlineMenu,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { HiOutlineShoppingBag } from "react-icons/hi";

import { Link, NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GrClose } from "react-icons/gr";
import { selectBasketItems } from "../../Redux/basketSlice";
import { useSelector } from "react-redux";

const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  color: white;
  transition: right 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Navbar2 = () => {
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const items = useSelector(selectBasketItems);

  const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  const [wishlist, setWishlist] = useState(getDatafromLS());

  return (
    <>
      <div className={showButton ? "fix-nav" : ""}>
        <div
          className={`nav-div3 ${
            showButton ? "sticky-nav fadeInDown-nav" : ""
          }`}
        >
          <div onClick={() => navigate("/")} className={"logo-nav2"}>
            Bansport
          </div>
          <ul className={"ul-black-nav"}>
            <li className="li-black">
              {" "}
              <Link to={"/Category"}>Men</Link>
            </li>
            <li className="li-black">Women</li>
          </ul>

          <div className={"search-nav-gray"}>
            <input
              className={"search-input-nav1"}
              placeholder="Search..."
              type="text"
              name=""
              id=""
            />
            <AiOutlineSearch color="#80808063" />
          </div>
          <div className="      log-nav-icons">
            <div className={"navbar-right-h-icons-black"}>
              <Link to={"/Wishlist"} className="nav-links">
                <div className="cart-count-nav">
                  <AiOutlineHeart size={22} />
                  <span>({wishlist.length})</span>
                </div>
              </Link>

              <Link to={"/Cart"} className="nav-links">
                <div className="cart-count-nav">
                  <HiOutlineShoppingBag color="gray" size={22} />({items.length}
                  )
                </div>
              </Link>
            </div>
          </div>
          <div className="mb-icons-nav">
            <div className="mb-icons-nav1">
           

              <Link
                to={"/Wishlist"}
                className={showButton ? "nav-links" : "nav-links-black"}
              >
                <AiOutlineHeart color="gray" size={22} />
              </Link>
              <Link
                to={"/Cart"}
                className={showButton ? "nav-links" : "nav-links-black"}
              >
                <HiOutlineShoppingBag color="gray" size={22} />
              </Link>
              <span
                onClick={() => setIsOpen(true)}
                className={showButton ? "nav-links" : "nav-links-black"}
              >
                <AiOutlineMenu color="gray" size={22} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <StyledOffCanvas isOpen={isOpen}>
        <div className="head-mobile-menu">
          <div className="close-icon-nav">
            <GrClose color="black" size={20} onClick={() => setIsOpen(false)} />
          </div>
          <Link
                to={"/Cart"}
                className={showButton ? "nav-links" : "nav-links-black"}
              >
                <AiOutlineSearch color="gray" size={22} />
              </Link>


          <div className="">
            <ul className={"side-bar-menu-ul"}>
              <li className={""}>
                <Link to={"/Category"}>Men</Link>
              </li>
              <li className={""}>
                <Link to={"/Category"}>Men</Link>
              </li>{" "}
            </ul>
          </div>
        </div>
      </StyledOffCanvas>
      <StyledOffCanvasOverlay
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
      />
    </>
  );
};

export default Navbar2;
