import React from "react";
import { BsArrowDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const CardNewArrivals = ({
  id,
  image,
  name,
  price,
  description,
  available,
  descriptionss,
  sizeChart,
  category,
  type,
  psize,
  newArrivals,
}) => {
  const navigate = useNavigate();



  const handleClick = () => {
    const data = {
      id,
      image,
      name,
      price,
      description,
      available,
      descriptionss,
      sizeChart,
      category,
      type,
      psize,
    };
    navigate('/Product', { state: data })

    
    
  };



  return (
    <>
    {newArrivals === true ? <>

        <div  className="card-div-main">
          <div className="left-h-card1">
            <img
              className="img-card-new-arr"
              src={image}
              alt=""
            />
          </div>
          <div className="right-h-card1">
            <h1 className="card-heading-p">{name}</h1>
            <h1 className="card-heading-h2">{description}</h1>

            <h1 className="card-heading-h3">Flat 10 % OFF</h1>
            <BsArrowDown />
            <button onClick={handleClick} className="btn-check-out1">Check Out</button>
          </div>
        </div>
  
     </> : <></>}
  
    </>
  );
};

export default CardNewArrivals;
