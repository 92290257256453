import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import ScrollTop from "../src/components/Scroll/ScrollTop";
import ScrollToTop from "../src/components/Scroll/ScrollToTop";
import { Toaster } from "react-hot-toast";
import Footer from "./components/Layout/Footer/Footer";
import Product from "./Pages/Product/Product";
import Navbar from "./components/Layout/Navbar/Navbar";
import Cart from "./Pages/Cart/Cart";
import CheckOut from "./Pages/Checkout/CheckOut";
import Category from "./Pages/Category/Category";
import Wishlist from "./Pages/Wishlist/Wishlist";
import Signin from "./Pages/Login/Signin";
import Signup from "./Pages/Login/Signup";
import Account from "./Pages/Account/Account";
import { Provider } from "react-redux";
import { store } from "./Store";
import Test from "./Pages/Test";
import Test2 from "./Pages/Test2";


const App = () => {
  return (
    <>
    
      <Provider store={store}>
        <Router>
          <Toaster />
        

          <ScrollTop />
          <ScrollToTop />

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Product" element={<Product />} />
            <Route exact path="/Cart" element={<Cart />} />
            <Route exact path="/CheckOut" element={<CheckOut />} />
            <Route exact path="/Category" element={<Category />} />
            <Route exact path="/Wishlist" element={<Wishlist />} />
            <Route exact path="/Signin" element={<Signin />} />
            <Route exact path="/Signup" element={<Signup />} />
            <Route exact path="/Account" element={<Account />} />
            <Route exact path="/Test" element={<Test />} />
            <Route exact path="/Test2" element={<Test2 />} />


          </Routes>
          <Footer />
        </Router>
      </Provider>
    </>
  );
};

export default App;
