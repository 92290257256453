import React, { useEffect, useState } from "react";
import "./Categories.css";
import NewCarousel from "./NewCarousel";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";

const Categories = () => {


  const [product, setproduct] = useState([]);

    useEffect(() => {
      const todosref = collection(db, "Categories");
      const q = query(todosref, orderBy("num", "asc"));
  
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setproduct(cities);
      });
  
      return () => {
        unsubscribe();
      };
    }, []);

  return (
    <>
      <div className="new-div1">
        <div className="new-div2">
          <div className="new-flex-1">
            <h1 className="new-h2">See Our Collection</h1>
            {/* <span className="line-texts"></span> */}
            <h1 className="new-h1">Categories </h1>
            {/* <span className="line-texts"></span> */}
          </div>
          <div className="new-c-top">
         
                      <NewCarousel   
                      
                      />
         
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
