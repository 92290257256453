import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Dealscard = ({
  id,
  image,
  name,
  price,
  description,
  available,
  descriptionss,
  sizeChart,
  category,
  type,
  psize,

  offer
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    const data = {
      id,
      image,
      name,
      price,
      description,
      available,
      descriptionss,
      sizeChart,
      category,
      type,
      psize,

    };
    navigate('/Product', { state: data })

    
    
  };


  return (
    <>

{offer === true ? <>

  <div onClick={handleClick} className="card-d1">

<div className="card-d2">
  <div className="card-ab ">
    <h1 className="offer-h1">upto 50% off </h1>

    <div className="btn-div-1  ">
      <button  className="btn-buy-card">Check Out</button>
    </div>
  </div>
  <img
    className="card-img"
    src={image}
    alt=""
  />
</div>

</div>
</> : <></>}
       
        

    </>
  );
};

export default Dealscard;
