import React, { useEffect, useState } from "react";
import "./Wishlist.css";
import Navbar2 from "../../components/Layout/Navbar/Navbar2";
import Wishcard from "./Wishcard";
import { useNavigate } from "react-router-dom";
const Wishlist = () => {
  const navigate = useNavigate();
  const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  const [wishlist, setWishlist] = useState(getDatafromLS());

  // saving data to local storage
  useEffect(() => {
    localStorage.setItem("Wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const deleteBook = (id) => {
    const filteredBooks = wishlist.filter((element, index) => {
      return element.id !== id;
    });
    setWishlist(filteredBooks);
  };

  return (
    <>
      <Navbar2 />

      {wishlist.length === 0 ? (
        <>
          <div className="cate-div1">
            <div className="cate-div2">
              <div className="cate-div">
                <div className="empty-cart-div">
                  <h1 className="cart-head-head">Your Wishlist is Empty</h1>
                  <button
                    onClick={() => navigate("/")}
                    className="btn-empty-cart"
                  >
                    Continue Shoping
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="cate-div1">
          <div className="cate-div2">
            <div className="cate-div">
              <h1 className="cate-heding">Wishlist</h1>
              <div className="btn-delete-btn">
                <button
                  className="btn btn-danger btn-md"
                  onClick={() => setWishlist([])}
                >
                  Remove All
                </button>
              </div>
              <div className="wish-cards-fun">
                {wishlist.map((item) => (
                  <>
                    <Wishcard
                      id={item.id}
                      name={item.name}
                      image={item.image}
                      price={item.price}
                      description={item.description}
                      descriptionss={item.descriptionss}
                      available={item.available}
                      sizeChart={item.sizeChart}
                      category={item.category}
                      type={item.type}
                      deleteitem={deleteBook}
                    />
                    {/* 
<div
      key={item.id}
      //  onClick={handleClick}
        className="card-head-c">
        <img className="card-img-c" src={item.image} alt="" />

        <div className="card-con-c">
          <h1 className="pro-name-h4">{item.name}</h1>

          <div className="card-flex-3">
            <h1 className="pro-name-h-1">{item.category}</h1>
          </div>
        </div>
        <button onClick={()=>deleteBook(item.id)}>delete</button>
      </div> */}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Wishlist;
